/* eslint-disable react-hooks/exhaustive-deps */
import {AsyncState, Firebase, hasErpRole, useCallable, useLastLogin} from '@ozark/common';
import event from '@ozark/common/event';
import firebase from 'firebase/compat/app';
import LogRocket from 'logrocket';
import {useEffect, useState} from 'react';

export const useAuthUser = () => {
  const {saveLastLoginDate} = useLastLogin();
  const [authUser, setAuthUser] = useState<AsyncState<firebase.User | null>>({promised: true});
  const [isUserLoggingIn, setIsUserLoggingIn] = useState(false);
  const [loginEvent] = useState(event<firebase.User>());
  const [logoutEvent] = useState(event<firebase.User>());
  const [onLogin, emitLogin] = loginEvent;
  const [onLogout, emitLogout] = logoutEvent;
  const {mfaLoginUsePass} = useCallable();

  // -----------
  // Watch for auth state changes
  // Fire login/logout events as needed
  useEffect(() => {
    if (isUserLoggingIn) {
      // Don't need to handle auth changes while login is processing because
      // `login` function checks user role to logout not ERP users
      return;
    }

    const unsubs = [
      Firebase.auth.onIdTokenChanged(updatedUser => {
        setAuthUser({promised: false, data: updatedUser});
        if (authUser.promised) return;
        if (updatedUser && !authUser.data) {
          console.log('emit login event');
          emitLogin(updatedUser);
        }
        if (!updatedUser && authUser.data) {
          console.log('emit logout event');
          emitLogout(authUser.data);
        }
      }),
    ];
    return () => unsubs.forEach(unsub => unsub());
  }, [authUser.promised, authUser.data, isUserLoggingIn]);

  // Used only during development, prints firebase token to the console.
  useEffect(() => {
    if (!authUser.promised && process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
      authUser.data?.getIdToken().then(token => console.log(token));
    }
    if (authUser.data?.uid) {
      LogRocket.identify(authUser.data?.uid, {
        name: authUser.data.displayName || '',
        email: authUser.data.email || '',
      });
    }
  }, [authUser.promised]);

  const logout = async () => {
    console.log('logging out');
    return Firebase.auth.signOut();
  };

  const login = async (email: string, password: string) => {
    setIsUserLoggingIn(true);

    try {
      const credential = await Firebase.auth.signInWithEmailAndPassword(email, password);
      const tokenResult = await credential.user?.getIdTokenResult();

      if (!hasErpRole(tokenResult?.claims?.role)) {
        await logout();
        console.error(
          'Failed to authorized user because this user is not ERP user',
          tokenResult?.claims?.role
        );

        setIsUserLoggingIn(false);
        throw new Error('User is not ERP user');
      }

      await saveLastLoginDate(credential.user);
      setIsUserLoggingIn(false);
    } catch (err) {
      setIsUserLoggingIn(false);
      throw err;
    }
  };

  const mfaLogin = async (email: string, password: string) => {
    setIsUserLoggingIn(true);

    try {
      console.log(`api key ${Firebase.apiKey.slice(0, 3)}...${Firebase.apiKey.slice(0, 5)} `);
      const mfaLoginResult = await mfaLoginUsePass({
        email: email,
        password: password,
        firebaseApiKey: Firebase.apiKey,
      });
      if (mfaLoginResult.status !== 'ok') {
        throw new Error('Unable to login');
      }
      setIsUserLoggingIn(false);
      return mfaLoginResult;
    } catch (err) {
      setIsUserLoggingIn(false);
      throw err;
    }
  };

  return {
    authUser,
    login,
    isUserLoggingIn,
    logout,
    onLogin,
    onLogout,
    mfaLogin,
  };
};
